import { ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { Inline, Inset, Stack } from 'syf-component-library/ui/spacing'
import { Small } from 'syf-component-library/ui/typography'

import i18n from 'strings/i18n'
import {
  FAR_CHART_LINE,
  FAR_DOLLAR_SIGN,
  FAR_GLOBE,
  FAR_MAGNIFYING_GLASS,
  FAR_SQUARE_SLIDERS
} from 'const/icons'
import theme from 'theme'
import {
  ButtonWrapper,
  ContentWrapper,
  CreditScoreBenefit,
  CreditViewSubtitle,
  FullWidthButton,
  GridItem,
  GridWrapper
} from './subcomponents'

interface CreditViewBenefits {
  id: number
  iconProp: IconProp
  benefitsKey:
    | 'scoreOverview'
    | 'managementTips'
    | 'scoreTrending'
    | 'nationalScore'
    | 'creditSimulator'
}

const creditScoreBenefits: CreditViewBenefits[] = [
  {
    id: 1,
    iconProp: FAR_MAGNIFYING_GLASS,
    benefitsKey: 'scoreOverview'
  },
  {
    id: 2,
    iconProp: FAR_DOLLAR_SIGN,
    benefitsKey: 'managementTips'
  },
  {
    id: 3,
    iconProp: FAR_CHART_LINE,
    benefitsKey: 'scoreTrending'
  },
  {
    id: 4,
    iconProp: FAR_GLOBE,
    benefitsKey: 'nationalScore'
  },
  {
    id: 5,
    iconProp: FAR_SQUARE_SLIDERS,
    benefitsKey: 'creditSimulator'
  }
]

const CreditViewContent = (): ReactElement => {
  return (
    <>
      <ContentWrapper>
        <CreditViewSubtitle as="h2">
          {i18n({ creditView: 'creditViewSubtitle' })}
        </CreditViewSubtitle>
        <Stack size="large" />
        <GridWrapper>
          {creditScoreBenefits.map(({ iconProp, benefitsKey }) => (
            <GridItem key={benefitsKey}>
              <FontAwesomeIcon icon={iconProp} color={theme.cyanBlue} />
              <Inline size="medium" />
              <CreditScoreBenefit as="h3" weight="semiBold">
                {i18n({ creditView: benefitsKey })}
              </CreditScoreBenefit>
            </GridItem>
          ))}
        </GridWrapper>
        <Stack size="large" />
        <ButtonWrapper>
          <FullWidthButton type="button" buttonType="primary">
            {i18n({ creditView: 'launchCreditView' })}
          </FullWidthButton>
        </ButtonWrapper>
      </ContentWrapper>
      <Inset vertical="base" horizontal="medium">
        <Stack size="large" respondToBreakpoints={['small']} />
        <Small>{i18n({ creditView: 'disclaimer' })}</Small>
        <Stack size="medium" />
        <Small>{i18n({ creditView: 'footerDisclaimer' })}</Small>
      </Inset>
    </>
  )
}

export default CreditViewContent
