import { ReactElement } from 'react'

import { Stack } from 'syf-component-library/ui/spacing'
import useWindowSize from 'syf-component-library/ui/customHooks/useWindowWidth'

import i18n from 'strings/i18n'
import config from 'const/config'
import useNavigateOnRedirect from 'hooks/useNavigateOnRedirect'
import {
  CreditViewBody,
  CreditViewImage,
  CreditViewPageWrapper,
  CreditViewTitle,
  StackLg
} from './subcomponents'
import CreditViewContent from './CreditViewContent'

const CreditView = (): ReactElement => {
  const windowWidth = useWindowSize()

  useNavigateOnRedirect()

  const imageSrc = `${config.ASSETS_HOST}/assets/vista/${
    windowWidth.isLessThanMobile
      ? 'credit_view_header_mobile.png'
      : 'credit-view-header.png'
  }`

  return (
    <CreditViewPageWrapper>
      <div>
        <CreditViewImage
          src={imageSrc}
          alt={`${i18n({ creditView: 'creditViewHeader' })}`}
          data-test="credit-view-header"
        />
      </div>
      <CreditViewBody>
        <StackLg />
        <CreditViewTitle weight="light">
          {i18n({ creditView: 'creditViewHeader' })}
        </CreditViewTitle>
        <Stack size="medium" respondToBreakpoints={['small']} />
        <CreditViewContent />
      </CreditViewBody>
    </CreditViewPageWrapper>
  )
}

export default CreditView
