import { ReactElement, useState } from 'react'
import AccountCardMFE from 'account-card-mfe'
import getMfeConfig from 'helpers/getMFEConfig'
import featureFlags from 'const/featureFlags'
import { useAccountFeatures } from 'const/syfApiQueries'
import CrossBorderModal from 'App/CrossBorderModal'
import { AccountCardHandlers } from 'account-card-mfe/lib/mfe/AccountCardMFE/AccountCardMFE.types'
import useCTAHandlers from '../actions/useCTAHandlers'
import { CardColumn } from '../subcomponents'

interface AccountCardProps {
  accountId: string
  cardIndex: number
}

const AccountCard = ({
  accountId,
  cardIndex
}: AccountCardProps): ReactElement => {
  const [isCrossBorderVisible, setIsCrossBorderVisible] = useState(false)

  const mfeConfig = getMfeConfig('mfe')
  const {
    isCBREnabled,
    isAccountDetailsEnabled,
    isRewardsDeeplinkEnabled,
    isAccountUpgradingMessageEnabled,
    isPromotionsMfeEnabled
  } = featureFlags

  const { data: accountFeatures } = useAccountFeatures(accountId)

  const handlers = useCTAHandlers(accountId, setIsCrossBorderVisible)

  /*
   * Temp fix - (TODO)"accountFeatures.accessToPromotions" logic to be removed
   * from here and moved to account-card-mfe with the upcoming version bump
   */
  const isPromotionsEnabled =
    isPromotionsMfeEnabled && accountFeatures?.accessToPromotions

  const eventHandlers: AccountCardHandlers = {
    handleActivityClick: handlers.handleActivityClick,
    handleDetailsClick: isAccountDetailsEnabled
      ? handlers.handleDetailsClick
      : undefined,
    handleMAPClick: handlers.handleMAPClick,
    handleAccountServicesClick: handlers.handleAccountServicesClick,
    handleRewardsClick: isRewardsDeeplinkEnabled
      ? handlers.handleRewardsClick
      : undefined,
    handleStatementsClick: handlers.handleStatementsClick,
    handleCBRClick: isCBREnabled ? handlers.handleCBRClick : undefined,
    handlePromotionsClick: isPromotionsEnabled
      ? handlers.handlePromotionsClick
      : undefined,
    handleRegisterAccountClick: handlers.handleRegisterAccountClick,
    handleViewPaymentPlanOrOption: handlers.handleCollectionClick
  }

  return (
    <CardColumn $cardIndex={cardIndex}>
      <AccountCardMFE
        isQueryClientPresent
        mfeConfig={mfeConfig}
        accountId={accountId}
        featureFlags={{
          isNotificationsEnabled: true,
          isCBREnabled,
          isAccountUpgradingMessageEnabled,
          isPreDeliveryMessageEnabled: true,
          isIRLEnabled: featureFlags.isIRLEnabled
        }}
        eventHandlers={eventHandlers}
        handleAccountLink={handlers.handleAccountClick}
        shouldCallCreditAccountsQuery
      />
      <CrossBorderModal
        isOpen={isCrossBorderVisible}
        setIsOpen={setIsCrossBorderVisible}
        url={accountFeatures?.rewardDetails?.rewardsPlatformDestination}
      />
    </CardColumn>
  )
}

export default AccountCard
