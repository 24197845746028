import { ReactElement } from 'react'

import SyfAlerts from 'alerts-mfe'
import getMFEConfig from 'helpers/getMFEConfig'
import useNavigateOnRedirect from 'hooks/useNavigateOnRedirect'
import useProfileNavigate from 'hooks/useProfileNavigate'
import { PlatformType } from 'alerts-mfe/const/alertsConfig'

const Alerts = (): ReactElement => {
  const { handleProfileClick } = useProfileNavigate()

  const mfeConfig = getMFEConfig('mfe')

  useNavigateOnRedirect()

  return (
    <SyfAlerts
      config={mfeConfig}
      alertType="all"
      handleProfileClick={handleProfileClick}
      platform={PlatformType.DIGITAL_SERVICING}
    />
  )
}

export default Alerts
