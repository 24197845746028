import { ReactElement } from 'react'
import { H5 } from 'syf-component-library/ui/typography'
import i18n from 'strings/i18n'
import redirectToUrl from 'helpers/redirectToUrl'
import config from 'const/config'
import useWindowSize from 'syf-component-library/ui/customHooks/useWindowWidth'
import {
  Image,
  ImageWrapper,
  MarketPlaceButton,
  MarketPlaceContainer,
  MarketPlaceContent,
  MarketPlaceDescription,
  MarketPlaceGrid,
  MarketPlaceTextContainer
} from './subcomponents'

const MarketPlace = (): ReactElement => {
  const windowWidth = useWindowSize()
  const handleExploreOffers = () => {
    redirectToUrl('https://www.synchrony.com/marketplace')
  }

  const imageUrl = `${config.ASSETS_HOST}/assets/vista/${
    windowWidth.isLessThanMobile
      ? 'market_place_mobile.svg'
      : 'market_place_desktop.svg'
  }`

  return (
    <MarketPlaceContainer>
      <MarketPlaceGrid>
        <ImageWrapper>
          <Image
            src={imageUrl}
            alt="Market Place"
            width="100%"
            height="100"
            data-test="marketplaceimage"
          />
        </ImageWrapper>
        <MarketPlaceContent>
          <MarketPlaceTextContainer>
            <H5 weight="bold">{i18n({ marketPlace: 'title' })}</H5>

            <MarketPlaceDescription>
              {i18n({ marketPlace: 'description' })}
            </MarketPlaceDescription>
          </MarketPlaceTextContainer>
          <MarketPlaceButton
            buttonType="primary"
            onClick={handleExploreOffers}
            data-type="Session logout"
            data-reason="explore offers"
            data-object="button"
            data-test="exploreOffer"
            data-analytics="clicktrackingevent"
          >
            {i18n({ button: 'exploreOffers' })}
          </MarketPlaceButton>
        </MarketPlaceContent>
      </MarketPlaceGrid>
    </MarketPlaceContainer>
  )
}
export default MarketPlace
