import { ReactElement, useEffect } from 'react'
import { Provider } from 'react-redux'
import store from 'redux/store'
import Apptimize from '@apptimize/apptimize-web-sdk'

import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import queryClient from 'const/queryClient'
import CookieEnabled from 'guards/CookieEnabled'
import { IVAChatStatusProvider } from 'iva-react-web-ui'

import config from 'const/config'
import Dashboard from './Dashboard'

const App = (): ReactElement => {
  const onApptimizeInitialized = () => {
    Apptimize.track('Apptimize Initialized')
    // Set a flag to indicate Apptimize data is available and content can be displayed
    // eslint-disable-next-line no-console
    console.log(
      `Apptimize Test Feature Flag - ${Apptimize.isFeatureFlagEnabled(
        'testFlagApptimize'
      )}`
    )
  }

  useEffect(() => {
    Apptimize.setOnApptimizeInitializedCallback(onApptimizeInitialized)
    Apptimize.setup(config.APPTIMIZE_APP_KEY)
  }, [])

  return (
    <CookieEnabled>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <IVAChatStatusProvider>
            <Dashboard />
          </IVAChatStatusProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </Provider>
    </CookieEnabled>
  )
}

export default App
