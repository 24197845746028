import { ReactElement } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateModalStatus } from 'redux/modals/actions'
import { RootState } from 'redux/store'

import featureFlags from 'const/featureFlags'
import getMFEConfig from 'helpers/getMFEConfig'
import { makeAPaymentModalContainerId } from 'const/modalContainerId'
import { useAccountSummary, useCreditAccounts } from 'const/syfApiQueries'
import findAccount from 'helpers/findAccount'
import getHelpNumber from 'helpers/getHelpNumbers'
import getLanguageFromStorage from 'helpers/getLanguageFromStorage'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import triggerQualtrics from 'helpers/triggerQualtrics'
import PaymentModal from 'make-a-payment/index'

const MakePaymentModal = (): ReactElement => {
  const { modalId, selectedAccount } = useSelector(
    (state: RootState) => ({
      modalId: state.modals.modalId,
      // TODO: pass in as prop when MAP accepts new modalTriggerId
      modalTriggerId: state.modals.modalTriggerId,
      selectedAccount: state.modals.selectedAccount
    }),
    shallowEqual
  )

  const { data: { accounts = [] } = {} } = useCreditAccounts()
  const { data: accountSummary } = useAccountSummary(selectedAccount)

  const dispatch = useDispatch()
  const account = findAccount(accounts, selectedAccount)
  const accountLast4 = account?.info?.accountLast4
  const clientId = accountSummary?.product?.clientId
  const { type: productType } = account?.product || {}
  const helpNumber = getHelpNumber(clientId, productType)
  const { hosts } = getMFEConfig('mfe')
  const mapFeatureFlags = {
    isAvailableToSpendAnimationEnabled:
      featureFlags.isAvailableToSpendAnimationEnabled,
    isLateFeeDateEnabled: featureFlags.isLateFeeDateEnabled,
    isManagePaymentMethodsEnabled: featureFlags.isManagePaymentMethodsEnabled,
    isProgressBarEnabled: featureFlags.isProgressBarEnabled,
    isSavingCheckingEnabled: featureFlags.isSavingCheckingEnabled,
    isSecuredInstallmentEnabled: featureFlags.isSecuredInstallmentEnabled
  }

  const lang = getLanguageFromStorage()

  return (
    <PaymentModal
      id="make-a-payment"
      accountLast4={accountLast4}
      containerId={makeAPaymentModalContainerId(selectedAccount)}
      isOpen={modalId === 'MAP'}
      handleClose={() => dispatch(updateModalStatus('', '', true))}
      accountId={selectedAccount}
      triggerAnalyticsEvent={triggerAnalyticsEvent}
      lang={lang}
      apiErrorCallback={(error) => error}
      featureFlags={mapFeatureFlags}
      hosts={hosts}
      helpNumber={helpNumber}
      handleCloseOnConversion={() => {
        triggerQualtrics()
      }}
    />
  )
}

export default MakePaymentModal
